* {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}

html {
  height: 100%;
}

body {
  font-family: "Nunito", sans-serif;
  line-height: 1.5;
  color: #495057;
  background-color: #f8f9fa;
  overflow-y: hidden;
  min-height: 100%;
}

.no-select {
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently */
}

button {
  outline: none;
  font-family: "Nunito", sans-serif;
}

strong {
  font-weight: bold;
}

.pull-text-right {
  text-align: right;
}

.pull-text-left {
  text-align: left;
}

.container {
  position: relative;
  margin: 0 auto;
}

.flex-container,
.flex-box {
  display: -webkit-flex; /* Safari */
  display: flex;
}

.flex-direction-column {
  -webkit-flex-direction: column;
  flex-direction: column;
}

.flex-justify-center {
  -webkit-justify-content: center; /* Safari */
  justify-content: center;
}

.flex-align-items-center {
  -webkit-align-items: center; /* Safari */
  align-items: center;
}

.flex-space-between {
  -webkit-justify-content: space-between; /* Safari */
  justify-content: space-between;
}

.app-header {
  position: relative;
}

.app-header .top-bar {
  position: relative;

  display: -webkit-flex; /* Safari */
  display: flex;

  -webkit-flex-flow: row nowrap;
  flex-flow: row nowrap;

  height: 65px;
  width: 100%;
  background-color: #34495e;
}

.app-header .top-bar .user-profile {
  position: relative;
}

.app-header .top-bar .user-profile .logout {
  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center;
  align-items: center;

  text-decoration: none;
  margin-left: 15px;

  color: #fff;
}

.app-header .top-bar .user-profile .user-info {
  position: relative;
  height: 40px;

  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: center; /* Safari */
  justify-content: center;

  -webkit-align-items: center; /* Safari */
  align-items: center;
}

.app-header .top-bar .user-profile .user-info span.avatar {
  width: 2rem;
  height: 2rem;
  line-height: 2.1rem;
  border-radius: 50%;
  display: inline-block;
  background: #ced4da no-repeat center/cover;
  position: relative;
  text-align: center;
  color: #868e96;
  font-weight: 600;
  vertical-align: bottom;
  font-size: 0.875rem;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  margin-right: 10px;
}

.app-header .top-bar .user-profile .user-info span {
  font-size: 14px;
  color: #fff;
}

.app-header .top-bar .app-logo {
  display: -webkit-flex;
  display: flex;

  -webkit-justify-content: center; /* Safari */
  justify-content: center;

  -webkit-align-items: center; /* Safari */
  align-items: center;
}

.app-header .top-bar .app-logo span {
  font-weight: 800;
  font-size: 22px;
  color: #fff;
}

.app-header .service-type-filters-bar {
  display: -webkit-flex;
  display: flex;

  -webkit-align-items: center; /* Safari */
  align-items: center;

  height: 72px;
  background: #fff;
  box-shadow: 0 1px 4px 3px rgba(57, 59, 62, 0.05);
}

/* Image item */

.images > div {
  width: 1170px !important;
  margin: 40px auto 0px;
  right: -20px;
}

.images .image-item {
  position: relative;
  margin-bottom: 20px;
}

.images .image-item .image-action {
  position: absolute;
  bottom: 10px;

  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;

  width: 30px;
  height: 30px;

  background-color: #eee;
  border-radius: 50%;
  cursor: pointer;
}

.images .image-item .image-action.see-full-image {
  right: 50px;
}

.images .image-item .image-action.see-current-tags {
  right: 10px;
}

.images .image-item .image-action.see-current-tags.video-item-position {
  top: 10px;
}

.images .image-item .image-action.download-image {
  right: 90px;
}

.images .image-item .flags-section {
  position: absolute;
  top: 10px;
  right: 10px;
  z-index: 50;
  min-width: 80px;
  max-height: 80%;
  overflow-y: auto;
  margin-right: 5px;
  overflow-x: hidden;

  display: flex;
  display: -webkit-flex;

  align-items: flex-end;
  -webkit-align-items: flex-end;

  flex-direction: column;
  -webkit-flex-direction: column;
}

.bottom-flags-section {
  position: absolute;
  bottom: 10px;
  left: 10px;
  z-index: 50;
  min-width: 80px;
  max-height: 80%;
  overflow-y: auto;
  margin-right: 5px;
  overflow-x: hidden;

  display: flex;
  display: -webkit-flex;

  align-items: flex-end;
  -webkit-align-items: flex-end;

  flex-direction: column;
  -webkit-flex-direction: column;
}

.flags-section::-webkit-scrollbar {
  display: none; /* Safari and Chrome */
}

.images .image-item .flags-section .product-list-id-flag,
.images .image-item .flags-section .test-type-flag,
.images .image-item .flags-section .test-id-flag,
.images .image-item .flags-section .trolley-flag {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  height: 22px;
  border-radius: 10px;
  font-size: 12px;
  padding: 10px;
  font-weight: 600;
  min-width: 60px;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  margin-right: 5px;
}

.optimization-flag {
  display: flex;
  display: -webkit-flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  height: 22px;
  border-radius: 10px;
  font-size: 12px;
  padding: 10px;
  font-weight: 600;
  min-width: 60px;
  cursor: pointer;
  margin-bottom: 5px;
  text-align: center;
  margin-right: 5px;
  /*border: 1px solid;
  border-color: rgba(196, 19, 19, 0.692);*/
  min-width: 100px;
  color: #831515;
  background-color: #eeeeeee1;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 10px -2px,
    rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.images .image-item .flags-section .test-type-flag {
  background: #e74c3c;
  color: #fff;
}

.images .image-item .flags-section .trolley-flag {
  background: #467fcf;
  color: #fff;
}

.images .image-item .flags-section .product-list-id-flag,
.images .image-item .flags-section .test-id-flag {
  color: #6e7687;
  background-color: #e9ecef;
}

.images .image-item .image-check .image-check-input {
  position: absolute;
  z-index: -1;
  opacity: 0;
}

.images .image-item .image-check .image-check-figure {
  position: relative;
  padding: 10px;
  height: 320px;
  width: 100%;
  margin: 0;
  opacity: 0.64;
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
}

.detected-image {
  box-shadow: 0 0 5px 5px rgba(148, 2, 2, 0.897) !important;
  /*
  border: solid;
  border-color: rgba(255, 0, 0, 0.7);
  box-shadow: 0 0 5px 5px rgba(148, 2, 2, 0.897) !important;
  box-shadow: rgba(255, 0, 0, 0.24) 0px 3px 8px !important;
  box-shadow: 0 0 5px 5px red !important;
  */
}

.images .image-item .image-check .image-check-figure.video-item {
  opacity: 1;
}

.images .image-item .image-check .image-check-figure .video {
  position: relative;
  height: 320px;
  width: 360px;
  margin: -10px;
  border-radius: 4px;
}

.images
  .image-item
  .image-check
  .image-check-input:focus
  ~ .image-check-figure {
  border-color: #467fcf;
  box-shadow: 0 0 0 2px rgba(70, 127, 207, 0.25);
}

.images
  .image-item
  .image-check
  .image-check-input:checked
  ~ .image-check-figure {
  opacity: 1;
}

.images
  .image-item
  .image-check
  .image-check-input:checked
  ~ .image-check-figure:before {
  content: "\e92a";
  position: absolute;
  font-family: "feather";
  left: 10px;
  top: 10px;
  font-size: 14px;
  width: 18px;
  height: 18px;
  text-align: center;
  color: #fff;
  background: #2196f3;
  border-radius: 4px;
  z-index: 10;
}

.images
  .image-item
  .image-check
  .image-check-input:checked
  ~ .image-check-figure:before {
  opacity: 1;
}

/* Filters */

.service-type-filters-bar .current-client h4 {
  font-size: 20px;
}

.service-type-filters-bar .service-type-list {
  position: relative;
  margin-right: 10px;
  width: 180px;
}

.service-type-filters-bar .service-type-list .service-type-list-dropdown {
  position: absolute;
  top: 50px;
  background: #fff;
  width: 100%;
  border-radius: 10px;
  z-index: 70;
  font-size: 14px;
  border: 1px solid #e9e9e9;
}

.service-type-filters-bar .service-type-list .service-type-list-dropdown ul li {
  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  border-bottom: 1px solid #eee;
}

.service-type-filters-bar
  .service-type-list
  .service-type-list-dropdown
  ul
  li
  span {
  display: block;
  width: 100%;
  padding: 7px 10px;
  white-space: nowrap;
  font-size: 14px;
  overflow: hidden;
  text-overflow: ellipsis;
  cursor: pointer;
}

.service-type-filters-bar
  .service-type-list
  .service-type-list-dropdown
  ul
  li
  span:hover {
  background: #f8f8f8;
}

.select-box {
  position: relative;
  display: table;
  height: 35px;
  width: 100%;
  border-radius: 4px;
}

.select-box span {
  display: table-cell;
  height: 35px;
  width: 100%;
  vertical-align: middle;
  color: #495057;
  padding: 0px 15px;
  font-size: 14px;
  border: 1px solid #e9e9e9;
  border-right: none;
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.select-box .down-icon {
  position: relative;
  margin-left: auto;
  display: flex;
  display: -webkit-flex;

  justify-content: center;
  -webkit-justify-content: center;

  align-items: center;
  -webkit-align-items: center;

  width: 35px;
  height: 35px;
  color: #56a0f2;
  text-decoration: none;
  font-size: 18px;
  vertical-align: middle;
  text-align: center;
  border-left: none;
  border: 1px solid #e9e9e9;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  cursor: pointer;
}

.select-box .down-icon:hover {
  background: #f8f8f8;
}

.tag {
  font-size: 0.75rem;
  color: #6e7687;
  background-color: #e9ecef;
  border-radius: 3px;
  padding: 0 0.5rem;
  margin-right: 5px;
  line-height: 2em;
  display: inline-flex;
  cursor: default;
  font-weight: 400;
  font-weight: 700;
  margin-top: 5px;
  margin-bottom: 5px;
  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.tag-item .tag {
  margin-top: 0;
  margin-bottom: 0;
}

.tag-addon {
  display: block;
  padding: 0 0.5rem;
  color: inherit;
  cursor: pointer;
  text-decoration: none;
  background: rgba(0, 0, 0, 0.06);
  margin: 0 -0.5rem 0 0.5rem;
  text-align: center;
  min-width: 1.5rem;
  max-width: 200px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-addon:hover {
  background: rgba(0, 0, 0, 0.16);
}

.selected-tags {
  position: relative;
  margin: 20px 0px 15px 0px;
}

.selected-tags h5 {
  font-size: 18px;
  font-weight: 800;
}

.selected-tags .tags {
  position: relative;
  margin-left: 20px;
}

.pagination {
  position: relative;
  margin-bottom: 40px;
}

.pagination ul {
  margin-left: auto;
}

.pagination .total-items {
  font-size: 14px;
}

.pagination ul li a {
  position: relative;
  padding: 6px 12px;
  margin-left: -1px;
  line-height: 1.42857143;
  font-size: 12px;
  color: #6e7687;
  text-decoration: none;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #e9e9e9;
}

.pagination ul li a:hover,
.pagination ul li.disabled a {
  background-color: #f8f8f8;
}

.pagination ul li.disabled a {
  cursor: not-allowed;
}

.pagination ul li.active a {
  background: #2196f3;
  color: #fff;
}

.pagination ul li.prev-page a {
  border-top-left-radius: 4px;
  border-bottom-left-radius: 4px;
}

.pagination ul li.next-page a {
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

/* Filters sidebar */

.filters-sidebar {
  position: fixed;
  right: -550px;
  top: 0;
  width: 550px;
  height: 100%;
  overflow-y: auto;
  padding: 40px;
  z-index: 100;
  background-color: #fff;
  box-shadow: 0 1px 4px 3px rgba(57, 59, 62, 0.05);
  transition: 0.25s;
}

.filters-sidebar.show {
  right: 0;
}

.filters-sidebar .sidebar-body {
  position: relative;
  margin-top: 20px;
  padding-bottom: 20px;
}

.filters-sidebar .sidebar-title h4 {
  font-size: 28px;
  font-weight: 800;
}

.filters-sidebar .close-sidebar {
  border: none;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  text-align: center;
}

.filters-sidebar .close-sidebar:hover {
  background: rgba(0, 0, 0, 0.16);
}

.filters-sidebar .close-sidebar i {
  line-height: 24px;
}

.filters-sidebar .form-input-single,
.filters-sidebar .form-input-group,
.form-input {
  position: relative;
  margin-bottom: 20px;
}

.image-tags-modal .form-input {
  margin-bottom: 0px;
}

.filters-sidebar .form-label,
.filters-sidebar .form-label-child,
.form-input .form-label {
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.filters-sidebar .form-input-single input,
.filters-sidebar .form-input-group input,
.form-input input {
  display: block;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
}

.filters-sidebar .form-input-group input {
  position: relative;
  -ms-flex: 1 1 auto;
  flex: 1 1 auto;
  width: 1%;

  border-left: none;
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
}

.filters-sidebar .input-group {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-align: stretch;
  align-items: stretch;
  width: 100%;
}

.filters-sidebar .input-group .input-group-prepend {
  position: relative;
}

.filters-sidebar .input-group-text:hover {
  background-color: #eee;
}

.filters-sidebar .input-group-text {
  display: -ms-flexbox;
  display: flex;
  -ms-flex-align: center;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;

  height: 2.375rem;
  width: 50px;
  padding: 0.375rem 0.75rem;
  margin-bottom: 0;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  text-align: center;
  white-space: nowrap;
  background-color: #fbfbfc;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 3px;

  cursor: pointer;
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.filters-sidebar.show .apply-filters-btn {
  position: fixed;
  display: block;
  z-index: 50;
  right: 0;
  bottom: 0;
}

.filters-sidebar .apply-filters-btn button {
  position: relative;
  background: #2ecc71;
  border: none;
  width: 550px;
  cursor: pointer;
  height: 55px;
  color: #fff;
  font-size: 16px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}

.filters-sidebar .apply-filters-btn button:hover {
  background: #27ae60;
}

.filters-sidebar .datetime-group {
  position: relative;
  padding: 20px;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 4px;
  margin-bottom: 20px;
}

.filters-sidebar .datetime-group .form-label-child {
  font-weight: 400;
}

.filters-sidebar .datetime-group .form-input-single {
  margin-bottom: 0px;
}

.react-datepicker {
  display: flex;
  display: -webkit-flex;
  color: #495057;
  border: 1px solid #ddd;
}

.react-datepicker__header {
  border-bottom: none;
  background-color: #f8f8f8;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle,
.react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f8f8f8;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__current-month,
.react-datepicker-time__header,
.react-datepicker-year-header {
  color: #495057;
}

.react-datepicker__day--selected,
.react-datepicker__day--in-selecting-range,
.react-datepicker__day--in-range,
.react-datepicker__month-text--selected,
.react-datepicker__month-text--in-selecting-range,
.react-datepicker__month-text--in-range,
.react-datepicker__quarter-text--selected,
.react-datepicker__quarter-text--in-selecting-range,
.react-datepicker__quarter-text--in-range {
  background-color: #3498db;
}

.react-datepicker__day--selected:hover,
.react-datepicker__day--in-selecting-range:hover,
.react-datepicker__day--in-range:hover,
.react-datepicker__month-text--selected:hover,
.react-datepicker__month-text--in-selecting-range:hover,
.react-datepicker__month-text--in-range:hover,
.react-datepicker__quarter-text--selected:hover,
.react-datepicker__quarter-text--in-selecting-range:hover,
.react-datepicker__quarter-text--in-range:hover {
  background-color: #2980b9;
}

.operators {
  position: absolute;
  display: block;
  border: 1px solid rgba(0, 40, 100, 0.12);
  left: 0;
  border-top: none;
  width: 100%;
  z-index: 100;
  background: #fff;
}

.operators ul li {
  display: flex;
  display: -webkit-flex;

  height: 30px;
  justify-content: center;
  -webkit-justify-content: center;

  align-items: center;
  -webkit-align-items: center;

  cursor: pointer;
}

.operators ul li:hover {
  background-color: #eee;
}

[data-tooltip] {
  cursor: pointer;
}

[data-tooltip]:before,
[data-tooltip]:after {
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid: DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  pointer-events: none;
}

/**
 * Tooltip Styles
 */

/* Base styles for the element that has a tooltip */
[data-tooltip] {
  position: relative;
  cursor: pointer;
}

/* Base styles for the entire tooltip */
[data-tooltip]:before,
[data-tooltip]:after {
  position: absolute;
  z-index: 60;
  visibility: hidden;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=0);
  opacity: 0;
  -webkit-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -webkit-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -moz-transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    -moz-transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  transition: opacity 0.2s ease-in-out, visibility 0.2s ease-in-out,
    transform 0.2s cubic-bezier(0.71, 1.7, 0.77, 1.24);
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
  pointer-events: none;
}

/* Show the entire tooltip on hover and focus */
[data-tooltip]:hover:before,
[data-tooltip]:hover:after {
  visibility: visible;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=100)";
  filter: progid:DXImageTransform.Microsoft.Alpha(Opacity=100);
  opacity: 1;
}

/* Base styles for the tooltip's directional arrow */
[data-tooltip]:before {
  border: 6px solid transparent;
  background: transparent;
  content: "";
}

/* Base styles for the tooltip's content area */
[data-tooltip]:after {
  padding: 8px;
  width: 90px;
  background-color: #000;
  background-color: hsla(0, 0%, 20%, 0.9);
  color: #fff;
  content: attr(data-tooltip);
  font-size: 14px;
  line-height: 1.2;
  border-radius: 4px;
}

/* Directions */

/* Top (default) */
[data-tooltip]:before,
[data-tooltip]:after,
.tooltip-top:before,
.tooltip-top:after {
  bottom: 100%;
  left: 50%;
}

[data-tooltip]:before,
.tooltip-top:before {
  margin-left: -6px;
  margin-bottom: -12px;
  border-top-color: #000;
  border-top-color: hsla(0, 0%, 20%, 0.9);
}

/* Horizontally align top/bottom tooltips */
[data-tooltip]:after,
.tooltip-top:after {
  margin-left: -55px;
}

[data-tooltip]:hover:before,
[data-tooltip]:hover:after,
.tooltip-top:hover:before,
.tooltip-top:hover:after,
.tooltip-top:focus:before,
.tooltip-top:focus:after {
  -webkit-transform: translateY(-12px);
  -moz-transform: translateY(-12px);
  transform: translateY(-12px);
}

/* Left */
.tooltip-left:before,
.tooltip-left:after {
  right: 100%;
  bottom: 50%;
  left: auto;
}

.tooltip-left:before {
  margin-left: 0;
  margin-right: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-left-color: #000;
  border-left-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-left:hover:before,
.tooltip-left:hover:after,
.tooltip-left:focus:before,
.tooltip-left:focus:after {
  -webkit-transform: translateX(-12px);
  -moz-transform: translateX(-12px);
  transform: translateX(-12px);
}

/* Bottom */
.tooltip-bottom:before,
.tooltip-bottom:after {
  top: 100%;
  bottom: auto;
  left: 50%;
}

.tooltip-bottom:before {
  margin-top: -12px;
  margin-bottom: 0;
  border-top-color: transparent;
  border-bottom-color: #000;
  border-bottom-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-bottom:hover:before,
.tooltip-bottom:hover:after,
.tooltip-bottom:focus:before,
.tooltip-bottom:focus:after {
  -webkit-transform: translateY(12px);
  -moz-transform: translateY(12px);
  transform: translateY(12px);
}

/* Right */
.tooltip-right:before,
.tooltip-right:after {
  bottom: 50%;
  left: 100%;
}

.tooltip-right:before {
  margin-bottom: 0;
  margin-left: -12px;
  border-top-color: transparent;
  border-right-color: #000;
  border-right-color: hsla(0, 0%, 20%, 0.9);
}

.tooltip-right:hover:before,
.tooltip-right:hover:after,
.tooltip-right:focus:before,
.tooltip-right:focus:after {
  -webkit-transform: translateX(12px);
  -moz-transform: translateX(12px);
  transform: translateX(12px);
}

/* Move directional arrows down a bit for left/right tooltips */
.tooltip-left:before,
.tooltip-right:before {
  top: 3px;
}

/* Vertically center tooltip content for left/right tooltips */
.tooltip-left:after,
.tooltip-right:after {
  margin-left: 0;
  margin-bottom: -16px;
}

.filters-label {
  margin-right: 10px;
  font-weight: 600;
}

.btn.btn-success {
  background: #2ecc71;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 800;
}

.btn.btn-success:hover {
  background-color: #2cc06b;
}

.modal {
  position: fixed;

  width: 100%;
  height: 100%;
  overflow-y: auto;

  left: 0;
  top: 0;
  background: rgba(0, 0, 0, 0.5);
  z-index: 999;
  border-radius: 4px;

  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.28);

  opacity: 0;
  visibility: hidden;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0.3s;
  transition: opacity 0.3s 0s, visibility 0s 0.3s;
}

.modal.is-visible {
  opacity: 1;
  visibility: visible;
  -webkit-transition: opacity 0.3s 0s, visibility 0s 0s;
  -moz-transition: opacity 0.3s 0s, visibility 0s 0s;
  transition: opacity 0.3s 0s, visibility 0s 0s;
}

.modal .modal-container {
  position: relative;

  display: flex;
  display: -webkit-flex;

  width: 100%;

  margin: 100px auto 40px;

  border-radius: 4px;

  padding: 10px;

  background: #fff;
  -webkit-transform: translateY(-40px);
  -moz-transform: translateY(-40px);
  -ms-transform: translateY(-40px);
  -o-transform: translateY(-40px);
  transform: translateY(-40px);
  -webkit-backface-visibility: hidden;
  -webkit-transition-property: -webkit-transform;
  -moz-transition-property: -moz-transform;
  transition-property: transform;
  -webkit-transition-duration: 0.3s;
  -moz-transition-duration: 0.3s;
  transition-duration: 0.3s;
}

.modal.image-tags-modal .modal-container,
.modal.tags-modal .modal-container {
  max-width: 1000px;
  min-height: 270px;
}

.modal.confirm-modal .modal-container {
  flex-direction: column;
  -webkit-flex-direction: column;

  max-width: 500px;
}

.modal.update-images-modal .modal-container {
  max-width: 400px;
}

.modal.is-visible .modal-container {
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -ms-transform: translateY(0);
  -o-transform: translateY(0);
  transform: translateY(0);
}

.modal .modal-container .modal-close {
  position: absolute;
  top: -15px;
  right: -15px;
  z-index: 100;
}

.modal .modal-container .modal-close button {
  position: relative;
  border: none;
  font-size: 16px;
  background-color: #eee;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  -webkit-box-shadow: 0 1px 5px rgba(0, 0, 0, 0.28);
  box-shadow: 0 1px 5px rgba(0, 0, 0, 0.28);
}

.modal .modal-container .modal-close button:hover {
  background-color: #dadddf;
}

.modal .modal-container .modal-section-title h4 {
  font-weight: 600;
  font-size: 20px;
}

.modal .modal-container .modal-header button {
  border: none;
  font-size: 16px;
  background-color: rgba(0, 0, 0, 0.06);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  cursor: pointer;
  text-align: center;
}

.modal .modal-container .modal-header button i {
  vertical-align: middle;
  line-height: 1.7;
}

.modal .modal-container .modal-content {
  position: relative;
  padding: 15px;
  width: 100%;
}

.modal .modal-container .modal-content .modal-section-title {
  display: flex;
  display: -webkit-flex;

  justify-content: space-between;
  -webkit-justify-content: space-between;

  align-items: center;
  -webkit-align-items: center;

  margin-bottom: 20px;
}

.modal .modal-container .modal-content .confirmation-message {
  position: relative;
  margin-bottom: 20px;
}

.modal .modal-container .modal-content .confirmation-btn-container {
  display: flex;
  display: -webkit-flex;
}

.modal .modal-container .modal-content .confirmation-btn-container .btn-group {
  position: relative;
  margin-left: auto;
}

.modal
  .modal-container
  .modal-content
  .confirmation-btn-container
  .btn-group
  button {
  border: none;
  width: 120px;
  height: 40px;
  color: #fff;
  border-radius: 4px;
  cursor: pointer;
}

.modal
  .modal-container
  .modal-content
  .confirmation-btn-container
  .btn-group
  button.cancel-btn {
  background-color: #e74c3c;
}

.modal
  .modal-container
  .modal-content
  .confirmation-btn-container
  .btn-group
  button.cancel-btn:hover {
  background-color: #c0392b;
}

.modal
  .modal-container
  .modal-content
  .confirmation-btn-container
  .btn-group
  button.confirm-btn {
  background-color: #2ecc71;
}

.modal
  .modal-container
  .modal-content
  .confirmation-btn-container
  .btn-group
  button.confirm-btn {
  margin-left: 10px;
}

.modal
  .modal-container
  .modal-content
  .confirmation-btn-container
  .btn-group
  button.confirm-btn:hover {
  background-color: #27ae60;
}

.modal .modal-container .modal-content .tags-form-section {
  background: #f8f9fa;
  border-top-right-radius: 4px;
  border-bottom-right-radius: 4px;
}

.modal .modal-container .modal-content .form-section .input-box {
  position: relative;
  padding: 20px;
  background: #f8f8f8;
  border: 1px solid #eee;
  border-radius: 4px;
}

.modal
  .modal-container
  .modal-content
  .form-section
  .input-box
  .category-dropdown-container {
  margin-bottom: 0px;
  margin-top: 10px;
}

.modal .modal-container .modal-content .toggle-forms-btn,
.modal .modal-container .modal-content .apply-more-tags-btn {
  font-size: 12px;
  text-align: right;
  color: #3498db;
}

.modal .modal-container .modal-content .apply-more-tags-btn {
  display: block;
  margin: 10px 0px;
}

.modal .modal-container .modal-content .toggle-forms-btn:hover,
.modal .modal-container .modal-content .apply-more-tags-btn:hover {
  text-decoration: underline;
  cursor: pointer;
}

.modal .modal-container .modal-content .input-box:not(:last-child) {
  margin-bottom: 20px;
}

.modal .modal-container .modal-content .remove-tag-btn {
  position: absolute;
  top: -10px;
  right: -11px;
  border-radius: 50%;
  border: none;
  font-size: 14px;
  background-color: #e74c3c;
  border-radius: 50%;
  height: 30px;
  width: 30px;
  color: #fff;
  cursor: pointer;

  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;
}

.done-job {
  position: fixed;
  bottom: 0;
  padding: 10px 30px;
  position: absolute;
  right: 0;
}

.images-actions {
  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: space-between;
  -webkit-justify-content: space-between;

  padding: 0px 30px;

  background: #fff;
  position: fixed;
  bottom: 0;
  box-shadow: 0 -1px 0 rgba(76, 86, 103, 0.1),
    0 -2px 35px rgba(31, 37, 50, 0.12);
  z-index: 40;
  left: 0;
  width: 100%;
  height: 50px;
  transition: transform 0.3s ease;
}

.images-actions .actions-btn-group button {
  position: relative;

  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;

  border: none;
  color: #495057;
  border-radius: 50%;
  background-color: transparent;
  cursor: pointer;
  font-size: 20px;
  margin-right: 10px;
  vertical-align: middle;
}

.images-actions .actions-btn-group button.delete-btn {
  color: #e74c3c;
}

.images-actions .actions-btn-group {
  position: absolute;

  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;

  left: 0;
  right: 0;
  margin: auto;
  width: 200px;
}

.images-actions .select-all-images .select-all-images-checkbox,
.select-append-to {
  position: relative;
  width: 15px;
  height: 15px;
  margin-right: 10px;
}

.images-actions .select-all-images p {
  font-size: 14px;
}

.select-all-images-checkbox:after,
.select-append-to::after {
  content: "";
  background-color: #eee;
}

.select-all-images-checkbox:hover:after,
.select-append-to:hover:after {
  background-color: #dadddf;
}

.select-all-images-checkbox:checked:after,
.select-append-to:checked::after {
  content: "\e92a";
  font-family: "feather";
  color: #fff;
  background-color: #2196f3;
}

.select-all-images-checkbox:after,
.select-append-to::after,
.select-all-images-checkbox:checked:after,
.select-append-to:checked::after {
  display: block;
  width: 18px;
  height: 18px;
  margin-top: -2px;
  margin-left: -1px;
  border-radius: 4px;
  text-align: center;
  line-height: 1.3em;
  cursor: pointer;
}

.save-selected-dataset button {
  position: relative;
  background: #2ecc71;
  border: none;
  width: 100%;
  cursor: pointer;
  height: 30px;
  border-radius: 4px;
  color: #fff;
  width: 120px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}

.autocomplete-list {
  position: absolute;
  display: block;
  border: 1px solid rgba(0, 40, 100, 0.12);
  left: 15px;
  border-top: none;
  right: 15px;
  overflow-y: auto;
  max-height: 200px;
  z-index: 100;
  background: #fff;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px;
}

.autocomplete-list > div {
  position: relative;
  padding: 0.375rem 0.75rem;
  cursor: pointer;
}

.autocomplete-list > div:hover {
  background-color: #eee;
}

/* Alerts */

.alert {
  font-size: 0.9375rem;
  margin-bottom: 20px;
}

.alert-success {
  color: #316100;
  background-color: #dff1cc;
  border-color: #d2ecb8;
}

.alert-info {
  color: #24587e;
  background-color: #daeefc;
  border-color: #cbe7fb;
}

.alert-danger {
  background-color: #ff193f;
  color: #fff;
}

.alert {
  position: relative;
  padding: 0.75rem 1.25rem;
  border: 1px solid transparent;
  border-radius: 3px;
}

.row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  margin-right: -15px;
  margin-left: -15px;
}

.row.no-margins {
  margin: 0;
}

.add-tag-form button,
.choose-existing-tag-form button {
  background: #2ecc71;
  border: none;
  width: 100%;
  padding: 10px;
  border-radius: 4px;
  color: #fff;
  margin-top: 10px;
  cursor: pointer;
  font-weight: 800;
  font-family: "Nunito", sans-serif;
}

.col-sm-1,
.col-sm-2,
.col-sm-3,
.col-sm-4,
.col-sm-5,
.col-sm-6,
.col-sm-7,
.col-sm-8,
.col-sm-9,
.col-sm-10,
.col-sm-11,
.col-sm-12,
.col-md-1,
.col-md-2,
.col-md-3,
.col-md-4,
.col-md-5,
.col-md-6,
.col-md-7,
.col-md-8,
.col-md-9,
.col-md-10,
.col-md-11,
.col-md-12,
.col-lg-1,
.col-lg-2,
.col-lg-3,
.col-lg-4,
.col-lg-5,
.col-lg-6,
.col-lg-7,
.col-lg-8,
.col-lg-9,
.col-lg-10,
.col-lg-11,
.col-lg-12 {
  position: relative;
  width: 100%;
  min-height: 1px;
  padding-right: 15px;
  padding-left: 15px;
}

/* Access page styles */

.app-access-page {
  height: 100vh;
  width: 100%;

  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  justify-content: center;
  -webkit-justify-content: center;

  -webkit-flex-direction: column;
  flex-direction: column;
}

.app-access-page .app-logo-access-page {
  display: block;
  margin-bottom: 20px;
}

.app-access-page .app-logo-access-page span {
  font-weight: 800;
  font-size: 32px;
}

.app-access-page .form-section {
  position: relative;
  padding: 2rem;
  width: 24rem;

  display: flex;
  display: -webkit-flex;

  -webkit-flex-direction: column;
  flex-direction: column;

  overflow-wrap: break-word;
  background-color: rgb(255, 255, 255);
  border-radius: 4px;
  margin-bottom: 20px;
  background-color: #fff;
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center center;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.12);
}

.form-section-title {
  font-size: 20px;
  line-height: 1.2;
  font-weight: 400;
  margin-bottom: 1.5rem;
  text-align: center;
}

.form-section label {
  display: block;
  margin-bottom: 0.375rem;
  font-weight: 600;
  font-size: 0.875rem;
}

.form-section input {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
}

.form-section input[type="text"].is-invalid {
  border: 1px solid #ff193f;
}

.form-section .validation-error-message {
  display: block;
  margin: 0.375rem 0;
  font-weight: 600;
  font-size: 0.875rem;
  color: #ff193f;
}

.form-section .form-footer .btn {
  position: relative;
  background: #2ecc71;
  border: none;
  width: 100%;
  cursor: pointer;
  height: 40px;
  border-radius: 4px;
  color: #fff;
  margin-top: 20px;
  font-size: 14px;
  font-weight: 600;
  font-family: "Nunito", sans-serif;
}

.form-section .form-footer .btn:hover {
  background: #27ae60;
}

.form-section .forgot-password a,
.form-section .confirm-reset-password a,
.form-section .back-to-login a {
  font-size: 14px;
  font-weight: 500;
  text-decoration: none;
}

.form-section .forgot-password:hover,
.form-section .confirm-reset-password a:hover,
.form-section .back-to-login a:hover {
  text-decoration: underline;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.no-results {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #ecf0f1;
  word-break: break-word;
  font-size: 1.125rem;
  border: 1px solid #e9e9e9;
  text-align: center;
  padding: 4rem 2rem;
  border-radius: 4px;
  margin: 40px 0px;
}

.no-results .save-selected-dataset {
  margin-top: 20px;
}

.add-tags button {
  background-color: #3498db;
  border: none;
  color: #fff;
  position: relative;
  height: 35px;
  padding: 0.5em 0.7em;
  border-radius: 4px;
}

.btn {
  border: none;
  cursor: pointer;
  border-radius: 4px;
}

.open-filters-sidebar,
.open-tags-modal {
  position: relative;
  height: 35px;
  width: 35px;
  padding: 0.5em 0.7em;
  border: 1px solid transparent;
}

.open-filters-sidebar {
  background: #fff;
  border-color: #e9e9e9;
}

.open-tags-modal {
  color: #fff;
  background-color: #3498db;
  margin-right: 10px;
}

.open-filters-sidebar:hover {
  background-color: #f8f8f8;
}

.open-filters-sidebar i,
.open-tags-modal i {
  vertical-align: middle;
}

.add-tag-form .form-input {
  margin-bottom: 10px;
}

.add-tag-form .form-input:last-child {
  margin-bottom: 0px;
}

.select-dropdown {
  display: block;
  width: 100%;
  height: 2.375rem;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
  background-image: linear-gradient(45deg, transparent 50%, gray 50%),
    linear-gradient(135deg, gray 50%, transparent 50%),
    radial-gradient(#f8f8f8 70%, transparent 72%);
  background-position: calc(100% - 20px) calc(1em + 2px),
    calc(100% - 15px) calc(1em + 2px), calc(100% - 0.5em) 0.5em;
  background-size: 5px 5px, 5px 5px, 1.5em 1.5em;
  background-repeat: no-repeat;
  margin: 0;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  -webkit-appearance: none;
  -moz-appearance: none;
}

.tag-list .tag-item {
  display: flex;
  display: -webkit-flex;

  margin-bottom: 10px;
}

.tag-list .tag-item .tag-name {
  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  flex-basis: 150px;
  -webkit-flex-basis: 150px;

  padding: 5px 15px;
  overflow: hidden;
  border-bottom-left-radius: 4px;
  border-top-left-radius: 4px;

  background-color: #ecf0f1;
}

.tag-list .tag-item .tag-value {
  flex-grow: 1;
  flex-basis: 0;
  padding: 5px 15px;
  overflow: hidden;
  background: #f8f8f8;
}

.tag-list .tag-item .tag-value.category-dropdown-value {
  overflow: initial;
}

.tag-list .tag-item .tag-value input[type="text"] {
  display: block;
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.9375rem;
  font-weight: 400;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
}

.tag-list .tag-item .tag-value > p,
.tag-list .tag-item .tag-name > p {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.tag-list .tag-item .manage-tag {
  display: flex;
  display: -webkit-flex;

  flex-basis: 80px;

  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
  background-color: #ecf0f1;
  cursor: pointer;
}

.tag-list .tag-item .manage-tag button {
  border: none;
  width: 100%;
  background: #ecf0f1;
  text-align: center;
  cursor: pointer;
}

.tag-list .tag-item .manage-tag button.remove-tag,
.tag-list .tag-item .manage-tag button.edit-tag {
  background-color: #d9dee1;
}

.tag-list .tag-item .manage-tag button.remove-tag:hover {
  background-color: #e74c3c;
  color: #fff;
}

.tag-list .tag-item .manage-tag button.update-tag {
  background-color: #3498db;
  color: #fff;
}

.tag-list .tag-item .manage-tag button.edit-tag:hover {
  background-color: #2ecc71;
  color: #fff;
}

.images {
  display: none;
}

.images.show {
  display: block;
}

.tag-list .tag-item .tag-value .radio-input-group,
.form-input-single .radio-input-group {
  margin-bottom: 0;
  font-weight: 600;
  font-size: 0.875rem;
}

.form-input-single .radio-input-group label {
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-collapse: separate;
}

.selection-group {
  position: relative;
}

.input-box .selection-group {
  margin-top: 20px;
}

.selection-group .single-select {
  display: flex;
  display: -webkit-flex;
}

.selection-group .single-select label {
  border: 1px solid rgba(0, 40, 100, 0.12);
  margin-bottom: 0;
}

.selection-group .single-select input + label:not(:last-of-type) {
  border-right: none;
}

.selection-group .single-select input + label:first-of-type {
  border-top-left-radius: 0.25rem;
  border-bottom-left-radius: 0.25rem;
}

.selection-group .single-select input + label:last-of-type {
  border-top-right-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
}

.selection-group .field-label {
  font-size: 0.8125rem;
  color: #6c7378;
  display: block;
  margin-bottom: 0.75rem;
  line-height: 1.125rem;
}

.selection-group input {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;
  width: 0.0625rem;
  height: 0.0625rem;
}

.selection-group input + label {
  position: relative;
  width: 50%;
  background-color: #fff;
  color: #3498db;
  font-size: 14px;
  line-height: 1.5rem;
  padding: 5px 15px;
  text-align: center;
  cursor: pointer;
  display: table-cell;
  vertical-align: middle;
}

.selection-group input + label:hover {
  background-color: #e4f1fb;
}

.selection-group input:focus + label {
  position: relative;
  z-index: 1;
  color: #3498db;
}

.selection-group input:checked + label {
  background-color: #3498db;
  color: #fff;
  border: 0.0625rem solid #3498db;
  font-weight: 500;
}

/* Form inputs */

@media only screen and (min-width: 768px) {
  .container {
    width: 750px;
  }

  /* 
        flex: - This property is the shorthand for the flex-grow, flex-shrink and flex-basis.
        flex-grow - This property specifies the flex grow factor, which determines how much the flex item will grow relative to the rest of the flex items in the flex container when positive free space is distributed. 
        flex-shrink - The flex-shrink specifies the flex shrink factor, which determines how much the flex item will shrink relative to the rest of the flex items in the flex container when negative free space is distributed. 
        flex-basis - This property takes the same values as the width and height properties, and specifies the initial main size of the flex item, before free space is distributed according to the flex factors. 
    */

  .col-sm-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-sm-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-sm-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-sm-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-sm-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-sm-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-sm-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-sm-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-sm-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-sm-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-sm-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-sm-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }
}

@media only screen and (min-width: 992px) {
  .container {
    width: 970px;
  }

  /* 
        flex: - This property is the shorthand for the flex-grow, flex-shrink and flex-basis.
        flex-grow - This property specifies the flex grow factor, which determines how much the flex item will grow relative to the rest of the flex items in the flex container when positive free space is distributed. 
        flex-shrink - The flex-shrink specifies the flex shrink factor, which determines how much the flex item will shrink relative to the rest of the flex items in the flex container when negative free space is distributed. 
        flex-basis - This property takes the same values as the width and height properties, and specifies the initial main size of the flex item, before free space is distributed according to the flex factors. 
    */

  .col-md-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-md-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-md-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-md-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-md-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-md-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-md-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-md-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-md-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-md-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-md-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-md-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-col-md {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

@media only screen and (min-width: 1200px) {
  .container {
    width: 1170px;
  }
  /* 
        flex: - This property is the shorthand for the flex-grow, flex-shrink and flex-basis.
        flex-grow - This property specifies the flex grow factor, which determines how much the flex item will grow relative to the rest of the flex items in the flex container when positive free space is distributed. 
        flex-shrink - The flex-shrink specifies the flex shrink factor, which determines how much the flex item will shrink relative to the rest of the flex items in the flex container when negative free space is distributed. 
        flex-basis - This property takes the same values as the width and height properties, and specifies the initial main size of the flex item, before free space is distributed according to the flex factors. 
    */

  .col-lg-1 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 8.333333%;
    flex: 0 0 8.333333%;
    max-width: 8.333333%;
  }

  .col-lg-2 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 16.666667%;
    flex: 0 0 16.666667%;
    max-width: 16.666667%;
  }

  .col-lg-3 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 25%;
    flex: 0 0 25%;
    max-width: 25%;
  }

  .col-lg-4 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 33.333333%;
    flex: 0 0 33.333333%;
    max-width: 33.333333%;
  }

  .col-lg-5 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 41.666667%;
    flex: 0 0 41.666667%;
    max-width: 41.666667%;
  }

  .col-lg-6 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 50%;
    flex: 0 0 50%;
    max-width: 50%;
  }

  .col-lg-7 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 58.333333%;
    flex: 0 0 58.333333%;
    max-width: 58.333333%;
  }

  .col-lg-8 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 66.666667%;
    flex: 0 0 66.666667%;
    max-width: 66.666667%;
  }

  .col-lg-9 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 75%;
    flex: 0 0 75%;
    max-width: 75%;
  }

  .col-lg-10 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 83.333333%;
    flex: 0 0 83.333333%;
    max-width: 83.333333%;
  }

  .col-lg-11 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 91.666667%;
    flex: 0 0 91.666667%;
    max-width: 91.666667%;
  }

  .col-lg-12 {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 100%;
    flex: 0 0 100%;
    max-width: 100%;
  }

  .custom-col-lg {
    -webkit-box-flex: 0;
    -ms-flex: 0 0 20%;
    flex: 0 0 20%;
    max-width: 20%;
  }
}

.not-allowed {
  margin-top: 40px;
}

.images-container > div > span {
  border: 1px dotted #495057 !important;
  background-color: rgba(52, 152, 219, 0.2) !important;
}

.category-dropdown-container {
  position: relative;
}

.category-dropdown-container ul li {
  display: inline-block;
}

.category-dropdown-container button {
  display: block;
  min-height: 2.375rem;
  cursor: pointer;
  padding: 0.375rem 0.75rem;
  text-align: left;
  font-size: 0.9375rem;
  width: 100%;
  font-weight: 400;
  line-height: 1.6;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 40, 100, 0.12);
  border-radius: 4px;
}

.category-dropdown-container .category-dropdown {
  display: none;
  position: absolute;
  top: 70px;

  padding: 15px;
  z-index: 100;
  max-height: 400px;
  background: #fff;
  border: 1px solid rgba(0, 40, 100, 0.12);
}

.image-modal-input.category-dropdown-container {
  margin-bottom: 20px;
}

.image-modal-input.update-tag-input.category-dropdown-container {
  margin-bottom: 0;
  padding: 0;
}

.image-modal-input.update-tag-input.category-dropdown-container
  .category-dropdown {
  top: 40px;
}

.image-modal-input.category-dropdown-container .category-dropdown {
  left: 0;
  right: 0;
}

.filtering-input.category-dropdown-container .category-dropdown {
  left: 15px;
  right: 15px;
}

.category-dropdown-container .category-dropdown.show {
  display: block;
}

.category-dropdown-container .category-dropdown .form-input-single {
  position: relative;
  margin-bottom: 0px;
}

.category-dropdown-container .category-dropdown .autocomplete-list {
  margin-left: -15px;
  margin-right: -15px;
}

.category-dropdown-container .category-dropdown .add-new-value {
  position: absolute;
  top: 7px;
  right: 10px;
  font-size: 20px;
  color: #27ae60;
  cursor: pointer;
}

.image-modal-input.update-tag-input.category-dropdown-container
  .category-dropdown
  .add-new-value {
  top: 3px;
}

.watched-video {
  position: absolute;

  display: flex;
  display: -webkit-flex;

  align-items: center;
  -webkit-align-items: center;

  right: 55px;
  top: 10px;

  height: 30px;

  z-index: 50;
  font-size: 14px;

  padding-left: 25px;
  cursor: pointer;

  -webkit-user-select: none;
  -moz-user-select: none;
  user-select: none;
}

.watched-video input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

.watched-video .checkmark {
  position: absolute;
  left: 0;
  width: 18px;
  height: 18px;
  background-color: #eee;
  border-radius: 4px;
}

.watched-video input:checked ~ .checkmark:after {
  display: block;
  content: "\e92a";
  position: absolute;
  font-family: "feather";
  font-size: 14px;
  width: 18px;
  height: 18px;
  text-align: center;
  color: #fff;
  background: #2196f3;
  border-radius: 4px;
  z-index: 10;
}

.append-to-box {
  position: relative;
  margin: 20px 0px;

  justify-content: flex-end;
  -webkit-justify-content: flex-end;
}

.append-to-box p {
  font-size: 0.875rem;
}
